import { authService } from 'spec';

// Avoid using arrow functions here because of problems in functional context. (https://stackoverflow.com/questions/12995153/apply-not-working-as-expected)
// Please declare auth object concise as possible.
function defaultCheckAccess(claimNames) {
  return this.claims && claimNames.some((cn) => !!+this.claims[cn]);
}

export async function GetAuthObject() {
  const claims = await authService.getClaims();
  return {
    /* ********* below permissions will append to tailing of the desired permissions *********** */

    // At Least 1 claim be true --> AL1
    // Register --> R
    // Edit --> E
    // Normal Edit --> NE
    // Protected Edit --> PE
    // Register and Edit --> RE
    // Delete --> D
    // Print Information --> PI

    // *************** support claims ******************
    PersonAccountD: defaultCheckAccess.call({ claims }, ['afp.d']),
    PersonAccountPI: defaultCheckAccess.call({ claims }, ['afp.p.i']),
    PersonAccountRE: defaultCheckAccess.call({ claims }, ['afp.cou']),
    CashTransactionRE: defaultCheckAccess.call({ claims }, ['tc.cou']),
    BankTransactionRE: defaultCheckAccess.call({ claims }, ['bt.cou']),
    BranchesList: defaultCheckAccess.call({ claims }, ['ag.l']),
    TransferBetweenAccountsRE: defaultCheckAccess.call(
      {
        claims
      },
      ['t2a.cou']
    ),
    PersonAccountREorPIorD: defaultCheckAccess.call({ claims }, [
      'afp.d',
      'afp.p.i',
      'afp.cou'
    ]),
    WhatsappAccess: defaultCheckAccess.call({ claims }, ['w.a']),
    AccountUpdating: defaultCheckAccess.call({ claims }, ['a.u']),
    ChequeRE: defaultCheckAccess.call({ claims }, ['ch.cou']),
    TradeCurrencyRE: defaultCheckAccess.call({ claims }, ['bsc.cou']),
    BankAccountsList: defaultCheckAccess.call({ claims }, ['bo.d', 'bo.cou']),
    GetManagers: defaultCheckAccess.call({ claims }, ['m.c']),
    ShowCustomerProfile: defaultCheckAccess.call({ claims }, ['t.p.c']),
    Migration: defaultCheckAccess.call({ claims }, ['mig']),
    Ticketing: defaultCheckAccess.call({ claims }, ['tic']),
    UserList: defaultCheckAccess.call({ claims }, ['a.m.u']),
    ChargeSection: defaultCheckAccess.call({ claims }, ['a.m.u'])
    // *************** end support claims ******************
  };
}

export default GetAuthObject;
