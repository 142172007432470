import '@mui/lab/themeAugmentation';
import { createTheme as createMuiTheme } from '@mui/material/styles';
import breakpoints from './breakpoints';
import componentSizes from './componentSizes';
import components from './components';
import margin from './margin';
import padding from './padding';
import rotateIcon from './rotateIcon';
import shadows from './shadows';
import {
  englishTypography,
  pashtoTypography,
  persianTypography,
  persianWithEnglishNumberTypography
} from './typography';
import variants from './variants';

const defaultVariant = variants[0];

const createTheme = (
  name,
  getSystemSettingsQueryData = { isDigitsLatin: false },
  direction = 'ltr',
  layoutType = 'desktop'
) => {
  let themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = defaultVariant;
  }

  return createMuiTheme(
    {
      spacing: 4,
      direction,
      breakpoints,
      // @ts-ignore
      components: components(themeConfig, layoutType),
      typography:
        getSystemSettingsQueryData?.systemLanguage === 'en'
          ? englishTypography(layoutType)
          : getSystemSettingsQueryData?.systemLanguage === 'fap'
          ? pashtoTypography(layoutType)
          : getSystemSettingsQueryData?.isDigitsLatin
          ? persianWithEnglishNumberTypography(layoutType)
          : persianTypography(layoutType),
      shadows,
      palette: themeConfig.palette,
      padding: padding(layoutType),
      margin: margin(layoutType),
      componentSizes: componentSizes(layoutType),
      rotateIcon: rotateIcon(direction)
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      navbar: themeConfig.navbar,
      alert: themeConfig.alert,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
      text: themeConfig.text,
      chip: themeConfig.chip,
      icon: themeConfig.icon,
      card: themeConfig.card,
      tab: themeConfig.tab,
      wrapper: themeConfig.wrapper,
      table: themeConfig.table,
      stepper: themeConfig.stepper,
      radio: themeConfig.radio,
      select: themeConfig.select,
      checkbox: themeConfig.checkbox,
      textField: themeConfig.textField,
      reactSelect: themeConfig.reactSelect,
      passwordStrength: themeConfig.passwordStrength,
      tooltip: themeConfig.tooltip,
      constants: themeConfig.constants,
      chart: themeConfig.chart,
      mobile: themeConfig.mobile
    }
  );
};

export default createTheme;
