const persianTypography = (layoutType) => ({
  fontFamily: [
    'YekanBakhFaNum',
    '-apple-system',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(','),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  button: {
    textTransform: 'none'
  },
  header36: {
    fontSize: '2.25rem',
    fontWeight: '900',
    lineHeight: '2.5rem'
  },
  // ********************************** new Typographies ******************************************
  // "h" stands for header
  h1Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1.75rem'
        : layoutType === 'laptop'
        ? '1.625rem'
        : layoutType === 'tablet'
        ? '1.5rem'
        : '1.75rem',
    fontWeight: '900',
    lineHeight: '2.5rem'
  },
  h2Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1.5rem'
        : layoutType === 'laptop'
        ? '1.375rem'
        : layoutType === 'tablet'
        ? '1.25rem'
        : '1.5rem',
    fontWeight: '900',
    lineHeight: '1.75rem'
  },
  h3Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1.25rem'
        : layoutType === 'laptop'
        ? '1.125rem'
        : layoutType === 'tablet'
        ? '1rem'
        : '1.25rem',
    fontWeight: '900',
    lineHeight: '1.75rem'
  },
  h3Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '1.25rem'
        : layoutType === 'laptop'
        ? '1.125rem'
        : layoutType === 'tablet'
        ? '1rem'
        : '1.25rem',
    fontWeight: '500',
    lineHeight: '1.75rem'
  },
  h4Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1.125rem'
        : layoutType === 'laptop'
        ? '1rem'
        : layoutType === 'tablet'
        ? '.875rem'
        : '1.125rem',
    fontWeight: '900',
    lineHeight: '1.5rem'
  },
  h4Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '1.125rem'
        : layoutType === 'laptop'
        ? '1rem'
        : layoutType === 'tablet'
        ? '.875rem'
        : '1.125rem',
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  h5Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1rem'
        : layoutType === 'laptop'
        ? '.875rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '1rem',
    fontWeight: '900',
    lineHeight: '1.25rem'
  },
  h5Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '1rem'
        : layoutType === 'laptop'
        ? '.875rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '1rem',
    fontWeight: '700',
    lineHeight: '1.25rem'
  },
  h5Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '1rem'
        : layoutType === 'laptop'
        ? '.875rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '1rem',
    fontWeight: '500',
    lineHeight: '1.25rem'
  },
  h6Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '900',
    lineHeight: '1.125rem'
  },
  h6Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '700',
    lineHeight: '1.125rem'
  },
  h6Medium: {
    fontSize: layoutType === 'desktop' ? '.875rem' : '.75rem',
    fontWeight: '500',
    lineHeight: '1rem'
  },
  h7Heavy: {
    fontSize: '.75rem',
    fontWeight: '900',
    lineHeight: '.875rem'
  },
  h7Bold: {
    fontSize: '.75rem',
    fontWeight: '700',
    lineHeight: '.875rem'
  },
  h7Medium: {
    fontSize: '.75rem',
    fontWeight: '500',
    lineHeight: '.875rem'
  },
  // "c" stands for common
  c1Heavy: {
    fontSize: '1rem',
    fontWeight: '900',
    lineHeight: '1.5rem'
  },
  c1Bold: {
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: '1.5rem'
  },
  c1Medium: {
    fontSize: '1rem',
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  c2Bold: {
    fontSize: layoutType === 'desktop' ? '.8rem' : '.75rem',
    fontWeight: '700',
    lineHeight: '1.125rem'
  },
  c2Medium: {
    fontSize: '.875rem',
    fontWeight: '500',
    lineHeight: '1.125rem'
  },
  c3Heavy: {
    fontSize: '.75rem',
    fontWeight: '900',
    lineHeight: '.875rem'
  },
  c3Bold: {
    fontSize: '.75rem',
    fontWeight: '700',
    lineHeight: '1.125rem'
  },
  c3Medium: {
    fontSize: '.75rem',
    fontWeight: '500',
    lineHeight: '.875rem'
  },
  c4Bold: {
    fontSize: '.625rem',
    fontWeight: '700',
    lineHeight: '.75rem'
  },
  c4Medium: {
    fontSize: '.625rem',
    fontWeight: '500',
    lineHeight: '.75rem'
  },
  // "t" stands for table
  t1Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '1rem'
        : layoutType === 'laptop'
        ? '.875rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '1rem',
    fontWeight: '700',
    lineHeight: '1.5rem'
  },
  t2Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '900',
    lineHeight: '1.5rem'
  },
  t2Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '700',
    lineHeight: '1.5rem'
  },
  t3Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '.75rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.75rem',
    fontWeight: '700',
    lineHeight: '1.25rem'
  },
  chipBold: {
    fontSize:
      layoutType === 'desktop'
        ? '.75rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.75rem',
    fontWeight: '700',
    lineHeight: '1rem'
  },
  // "b" stands for button
  b1Heavy: {
    fontSize: '1.125rem',
    fontWeight: '900',
    lineHeight: '1.75rem'
  },
  b2Heavy: {
    fontSize: '1rem',
    fontWeight: '900',
    lineHeight: '1.5rem'
  },
  b3Heavy: {
    fontSize: '.875rem',
    fontWeight: '900',
    lineHeight: '1rem'
  },
  b4Heavy: {
    fontSize: '.75rem',
    fontWeight: '900',
    lineHeight: '1rem'
  },
  // "i" stands for input
  i1Bold: {
    fontSize: '.875rem',
    fontWeight: '700',
    lineHeight: '1.5rem'
  },
  i2Heavy: {
    fontSize: '.75rem',
    fontWeight: '900',
    lineHeight: '1rem'
  },
  i2Bold: {
    fontSize: '.75rem',
    fontWeight: '700',
    lineHeight: '1rem'
  },
  i3Bold: {
    fontSize: '.75rem',
    fontWeight: '700',
    lineHeight: '1.25rem'
  },
  i2Medium: {
    fontSize: '.75rem',
    fontWeight: '500',
    lineHeight: '1rem'
  },
  labelMedium: {
    fontSize: '.625rem',
    fontWeight: '500',
    lineHeight: '1rem'
  }
  // ********************************** end new Typographies ******************************************
});

const persianWithEnglishNumberTypography = (layoutType) => ({
  fontFamily: [
    'YekanBakh',
    '-apple-system',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(','),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  button: {
    textTransform: 'none'
  },
  header36: {
    fontSize: '2.25rem',
    fontWeight: '900',
    lineHeight: '2.5rem'
  },
  // ********************************** new Typographies ******************************************
  // "h" stands for header
  h1Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1.75rem'
        : layoutType === 'laptop'
        ? '1.625rem'
        : layoutType === 'tablet'
        ? '1.5rem'
        : '1.75rem',
    fontWeight: '900',
    lineHeight: '2.5rem'
  },
  h2Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1.5rem'
        : layoutType === 'laptop'
        ? '1.375rem'
        : layoutType === 'tablet'
        ? '1.25rem'
        : '1.5rem',
    fontWeight: '900',
    lineHeight: '1.75rem'
  },
  h3Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1.25rem'
        : layoutType === 'laptop'
        ? '1.125rem'
        : layoutType === 'tablet'
        ? '1rem'
        : '1.25rem',
    fontWeight: '900',
    lineHeight: '1.75rem'
  },
  h3Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '1.25rem'
        : layoutType === 'laptop'
        ? '1.125rem'
        : layoutType === 'tablet'
        ? '1rem'
        : '1.25rem',
    fontWeight: '500',
    lineHeight: '1.75rem'
  },
  h4Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1.125rem'
        : layoutType === 'laptop'
        ? '1rem'
        : layoutType === 'tablet'
        ? '.875rem'
        : '1.125rem',
    fontWeight: '900',
    lineHeight: '1.5rem'
  },
  h4Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '1.125rem'
        : layoutType === 'laptop'
        ? '1rem'
        : layoutType === 'tablet'
        ? '.875rem'
        : '1.125rem',
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  h5Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1rem'
        : layoutType === 'laptop'
        ? '.875rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '1rem',
    fontWeight: '900',
    lineHeight: '1.25rem'
  },
  h5Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '1rem'
        : layoutType === 'laptop'
        ? '.875rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '1rem',
    fontWeight: '700',
    lineHeight: '1.25rem'
  },
  h6Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '900',
    lineHeight: '1.125rem'
  },
  h6Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '700',
    lineHeight: '1.125rem'
  },
  h6Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '500',
    lineHeight: '1rem'
  },
  h7Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '.75rem'
        : layoutType === 'laptop'
        ? '.625rem'
        : layoutType === 'tablet'
        ? '.625rem'
        : '.75rem',
    fontWeight: '900',
    lineHeight: '.875rem'
  },
  h7Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '.75rem'
        : layoutType === 'laptop'
        ? '.625rem'
        : layoutType === 'tablet'
        ? '.625rem'
        : '.75rem',
    fontWeight: '700',
    lineHeight: '.875rem'
  },
  // "c" stands for common
  c1Heavy: {
    fontSize: '1rem',
    fontWeight: '900',
    lineHeight: '1.5rem'
  },
  c1Bold: {
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: '1.5rem'
  },
  c1Medium: {
    fontSize: '1rem',
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  c2Bold: {
    fontSize: layoutType === 'desktop' ? '.8rem' : '.75rem',
    fontWeight: '700',
    lineHeight: '1.125rem'
  },
  c2Medium: {
    fontSize: '.875rem',
    fontWeight: '500',
    lineHeight: '1.125rem'
  },
  c3Heavy: {
    fontSize: '.75rem',
    fontWeight: '900',
    lineHeight: '.875rem'
  },
  c3Bold: {
    fontSize: '.75rem',
    fontWeight: '700',
    lineHeight: '1.125rem'
  },
  c3Medium: {
    fontSize: '.75rem',
    fontWeight: '500',
    lineHeight: '.875rem'
  },
  c4Bold: {
    fontSize: '.625rem',
    fontWeight: '700',
    lineHeight: '.75rem'
  },
  c4Medium: {
    fontSize: '.625rem',
    fontWeight: '500',
    lineHeight: '.75rem'
  },
  // "t" stands for table
  t1Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '1rem'
        : layoutType === 'laptop'
        ? '.875rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '1rem',
    fontWeight: '700',
    lineHeight: '1.5rem'
  },
  t2Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '900',
    lineHeight: '1.5rem'
  },
  t2Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '700',
    lineHeight: '1.5rem'
  },
  t3Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '.75rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.75rem',
    fontWeight: '700',
    lineHeight: '1.25rem'
  },
  chipBold: {
    fontSize:
      layoutType === 'desktop'
        ? '.75rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.75rem',
    fontWeight: '700',
    lineHeight: '1rem'
  },
  // "b" stands for button
  b1Heavy: {
    fontSize: '1.125rem',
    fontWeight: '900',
    lineHeight: '1.75rem'
  },
  b2Heavy: {
    fontSize: '1rem',
    fontWeight: '900',
    lineHeight: '1.5rem'
  },
  b3Heavy: {
    fontSize: '.875rem',
    fontWeight: '900',
    lineHeight: '1rem'
  },
  b4Heavy: {
    fontSize: '.75rem',
    fontWeight: '900',
    lineHeight: '1rem'
  },
  // "i" stands for input
  i1Bold: {
    fontSize: '.875rem',
    fontWeight: '700',
    lineHeight: '1.5rem'
  },
  i2Heavy: {
    fontSize: '.75rem',
    fontWeight: '900',
    lineHeight: '1rem'
  },
  i2Bold: {
    fontSize: '.75rem',
    fontWeight: '700',
    lineHeight: '1rem'
  },
  i2Medium: {
    fontSize: '.75rem',
    fontWeight: '500',
    lineHeight: '1rem'
  },
  labelMedium: {
    fontSize: '.625rem',
    fontWeight: '500',
    lineHeight: '1rem'
  }
  // ********************************** end new Typographies ******************************************
});

const pashtoTypography = (layoutType) => ({
  fontFamily: [
    'BhijYekan',
    '-apple-system',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(','),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  button: {
    textTransform: 'none'
  },
  header36: {
    fontSize: '2.25rem',
    fontWeight: '900',
    lineHeight: '2.5rem'
  },
  // ********************************** new Typographies ******************************************
  // "h" stands for header
  h1Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1.75rem'
        : layoutType === 'laptop'
        ? '1.625rem'
        : layoutType === 'tablet'
        ? '1.5rem'
        : '1.75rem',
    fontWeight: '900',
    lineHeight: '2.5rem'
  },
  h2Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1.5rem'
        : layoutType === 'laptop'
        ? '1.375rem'
        : layoutType === 'tablet'
        ? '1.25rem'
        : '1.5rem',
    fontWeight: '900',
    lineHeight: '1.75rem'
  },
  h3Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1.25rem'
        : layoutType === 'laptop'
        ? '1.125rem'
        : layoutType === 'tablet'
        ? '1rem'
        : '1.25rem',
    fontWeight: '900',
    lineHeight: '1.75rem'
  },
  h3Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '1.25rem'
        : layoutType === 'laptop'
        ? '1.125rem'
        : layoutType === 'tablet'
        ? '1rem'
        : '1.25rem',
    fontWeight: '500',
    lineHeight: '1.75rem'
  },
  h4Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1.125rem'
        : layoutType === 'laptop'
        ? '1rem'
        : layoutType === 'tablet'
        ? '.875rem'
        : '1.125rem',
    fontWeight: '900',
    lineHeight: '1.5rem'
  },
  h4Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '1.125rem'
        : layoutType === 'laptop'
        ? '1rem'
        : layoutType === 'tablet'
        ? '.875rem'
        : '1.125rem',
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  h5Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1rem'
        : layoutType === 'laptop'
        ? '.875rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '1rem',
    fontWeight: '900',
    lineHeight: '1.25rem'
  },
  h5Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '1rem'
        : layoutType === 'laptop'
        ? '.875rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '1rem',
    fontWeight: '700',
    lineHeight: '1.25rem'
  },
  h5Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '1rem'
        : layoutType === 'laptop'
        ? '.875rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '1rem',
    fontWeight: '500',
    lineHeight: '1.25rem'
  },
  h6Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '900',
    lineHeight: '1.125rem'
  },
  h6Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '700',
    lineHeight: '1.125rem'
  },
  h6Medium: {
    fontSize: layoutType === 'desktop' ? '.875rem' : '.75rem',
    fontWeight: '500',
    lineHeight: '1rem'
  },
  h7Heavy: {
    fontSize: '.75rem',
    fontWeight: '900',
    lineHeight: '.875rem'
  },
  h7Bold: {
    fontSize: '.75rem',
    fontWeight: '700',
    lineHeight: '.875rem'
  },
  h7Medium: {
    fontSize: '.75rem',
    fontWeight: '500',
    lineHeight: '.875rem'
  },
  // "c" stands for common
  c1Heavy: {
    fontSize: '1rem',
    fontWeight: '900',
    lineHeight: '1.5rem'
  },
  c1Bold: {
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: '1.5rem'
  },
  c1Medium: {
    fontSize: '1rem',
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  c2Bold: {
    fontSize: layoutType === 'desktop' ? '.8rem' : '.75rem',
    fontWeight: '700',
    lineHeight: '1.125rem'
  },
  c2Medium: {
    fontSize: '.875rem',
    fontWeight: '500',
    lineHeight: '1.125rem'
  },
  c3Heavy: {
    fontSize: '.75rem',
    fontWeight: '900',
    lineHeight: '.875rem'
  },
  c3Bold: {
    fontSize: '.75rem',
    fontWeight: '700',
    lineHeight: '1.125rem'
  },
  c3Medium: {
    fontSize: '.75rem',
    fontWeight: '500',
    lineHeight: '.875rem'
  },
  c4Bold: {
    fontSize: '.625rem',
    fontWeight: '700',
    lineHeight: '.75rem'
  },
  c4Medium: {
    fontSize: '.625rem',
    fontWeight: '500',
    lineHeight: '.75rem'
  },
  // "t" stands for table
  t1Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '1rem'
        : layoutType === 'laptop'
        ? '.875rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '1rem',
    fontWeight: '700',
    lineHeight: '1.5rem'
  },
  t2Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '900',
    lineHeight: '1.5rem'
  },
  t2Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '700',
    lineHeight: '1.5rem'
  },
  t3Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '.75rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.75rem',
    fontWeight: '700',
    lineHeight: '1.25rem'
  },
  chipBold: {
    fontSize:
      layoutType === 'desktop'
        ? '.75rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.75rem',
    fontWeight: '700',
    lineHeight: '1rem'
  },
  // "b" stands for button
  b1Heavy: {
    fontSize: '1.125rem',
    fontWeight: '900',
    lineHeight: '1.75rem'
  },
  b2Heavy: {
    fontSize: '1rem',
    fontWeight: '900',
    lineHeight: '1.5rem'
  },
  b3Heavy: {
    fontSize: '.875rem',
    fontWeight: '900',
    lineHeight: '1rem'
  },
  b4Heavy: {
    fontSize: '.75rem',
    fontWeight: '900',
    lineHeight: '1rem'
  },
  // "i" stands for input
  i1Bold: {
    fontSize: '.875rem',
    fontWeight: '700',
    lineHeight: '1.5rem'
  },
  i2Heavy: {
    fontSize: '.75rem',
    fontWeight: '900',
    lineHeight: '1rem'
  },
  i2Bold: {
    fontSize: '.75rem',
    fontWeight: '700',
    lineHeight: '1rem'
  },
  i3Bold: {
    fontSize: '.75rem',
    fontWeight: '700',
    lineHeight: '1.25rem'
  },
  i2Medium: {
    fontSize: '.75rem',
    fontWeight: '500',
    lineHeight: '1rem'
  },
  labelMedium: {
    fontSize: '.625rem',
    fontWeight: '500',
    lineHeight: '1rem'
  }
  // ********************************** end new Typographies ******************************************
});

const englishTypography = (layoutType) => ({
  fontFamily: [
    'Inter',
    '-apple-system',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(','),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  button: {
    textTransform: 'none'
  },
  header36: {
    fontSize: '2.25rem',
    fontWeight: '900',
    lineHeight: '2.5rem'
  },
  // ********************************** new Typographies ******************************************
  // "h" stands for header
  h1Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1.75rem'
        : layoutType === 'laptop'
        ? '1.625rem'
        : layoutType === 'tablet'
        ? '1.5rem'
        : '1.75rem',
    fontWeight: '900',
    lineHeight: '2.5rem'
  },
  h2Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1.5rem'
        : layoutType === 'laptop'
        ? '1.375rem'
        : layoutType === 'tablet'
        ? '1.25rem'
        : '1.5rem',
    fontWeight: '900',
    lineHeight: '1.75rem'
  },
  h3Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1.25rem'
        : layoutType === 'laptop'
        ? '1.125rem'
        : layoutType === 'tablet'
        ? '1rem'
        : '1.25rem',
    fontWeight: '900',
    lineHeight: '1.75rem'
  },
  h3Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '1.25rem'
        : layoutType === 'laptop'
        ? '1.125rem'
        : layoutType === 'tablet'
        ? '1rem'
        : '1.25rem',
    fontWeight: '500',
    lineHeight: '1.75rem'
  },
  h4Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1.125rem'
        : layoutType === 'laptop'
        ? '1rem'
        : layoutType === 'tablet'
        ? '.875rem'
        : '1.125rem',
    fontWeight: '900',
    lineHeight: '1.5rem'
  },
  h4Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '1.125rem'
        : layoutType === 'laptop'
        ? '1rem'
        : layoutType === 'tablet'
        ? '.875rem'
        : '1.125rem',
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  h5Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '1rem'
        : layoutType === 'laptop'
        ? '.875rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '1rem',
    fontWeight: '900',
    lineHeight: '1.25rem'
  },
  h5Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '1rem'
        : layoutType === 'laptop'
        ? '.875rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '1rem',
    fontWeight: '700',
    lineHeight: '1.25rem'
  },
  h6Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '900',
    lineHeight: '1.125rem'
  },
  h6Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '700',
    lineHeight: '1.125rem'
  },
  h6Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '500',
    lineHeight: '1rem'
  },
  h7Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '.75rem'
        : layoutType === 'laptop'
        ? '.625rem'
        : layoutType === 'tablet'
        ? '.625rem'
        : '.75rem',
    fontWeight: '900',
    lineHeight: '.875rem'
  },
  h7Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '.75rem'
        : layoutType === 'laptop'
        ? '.625rem'
        : layoutType === 'tablet'
        ? '.625rem'
        : '.75rem',
    fontWeight: '700',
    lineHeight: '.875rem'
  },
  // "c" stands for common
  c1Heavy: {
    fontSize: '1rem',
    fontWeight: '900',
    lineHeight: '1.5rem'
  },
  c1Bold: {
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: '1.5rem'
  },
  c1Medium: {
    fontSize: '1rem',
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  c2Bold: {
    fontSize: layoutType === 'desktop' ? '.8rem' : '.75rem',
    fontWeight: '700',
    lineHeight: '1.125rem'
  },
  c2Medium: {
    fontSize: '.875rem',
    fontWeight: '500',
    lineHeight: '1.125rem'
  },
  c3Heavy: {
    fontSize: '.75rem',
    fontWeight: '900',
    lineHeight: '.875rem'
  },
  c3Bold: {
    fontSize: '.75rem',
    fontWeight: '700',
    lineHeight: '1.125rem'
  },
  c3Medium: {
    fontSize: '.75rem',
    fontWeight: '500',
    lineHeight: '.875rem'
  },
  c4Bold: {
    fontSize: '.625rem',
    fontWeight: '700',
    lineHeight: '.75rem'
  },
  c4Medium: {
    fontSize: '.625rem',
    fontWeight: '500',
    lineHeight: '.75rem'
  },
  // "t" stands for table
  t1Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '1rem'
        : layoutType === 'laptop'
        ? '.875rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '1rem',
    fontWeight: '700',
    lineHeight: '1.5rem'
  },
  t2Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '900',
    lineHeight: '1.5rem'
  },
  t2Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '.875rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.875rem',
    fontWeight: '700',
    lineHeight: '1.5rem'
  },
  t3Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '.75rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.75rem',
    fontWeight: '700',
    lineHeight: '1.25rem'
  },
  chipBold: {
    fontSize:
      layoutType === 'desktop'
        ? '.75rem'
        : layoutType === 'laptop'
        ? '.75rem'
        : layoutType === 'tablet'
        ? '.75rem'
        : '.75rem',
    fontWeight: '700',
    lineHeight: '1rem'
  },
  // "b" stands for button
  b1Heavy: {
    fontSize: '1.125rem',
    fontWeight: '900',
    lineHeight: '1.75rem'
  },
  b2Heavy: {
    fontSize: '1rem',
    fontWeight: '900',
    lineHeight: '1.5rem'
  },
  b3Heavy: {
    fontSize: '.875rem',
    fontWeight: '900',
    lineHeight: '1rem'
  },
  b4Heavy: {
    fontSize: '.75rem',
    fontWeight: '900',
    lineHeight: '1rem'
  },
  // "i" stands for input
  i1Bold: {
    fontSize: '.875rem',
    fontWeight: '700',
    lineHeight: '1.5rem'
  },
  i2Heavy: {
    fontSize: '.75rem',
    fontWeight: '900',
    lineHeight: '1rem'
  },
  i2Bold: {
    fontSize: '.75rem',
    fontWeight: '700',
    lineHeight: '1rem'
  },
  i2Medium: {
    fontSize: '.75rem',
    fontWeight: '500',
    lineHeight: '1rem'
  },
  labelMedium: {
    fontSize: '.625rem',
    fontWeight: '500',
    lineHeight: '1rem'
  }
  // ********************************** end new Typographies ******************************************
});

export {
  persianTypography,
  pashtoTypography,
  englishTypography,
  persianWithEnglishNumberTypography
};
