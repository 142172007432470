import { Button, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { imageAddressPrefix } from 'spec';

const Error401 = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <Helmet title="خطای 401 - صفحه در دسترس شما نیست" />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Grid item mt="179px">
          <img
            src={`${imageAddressPrefix}static/img/errors/error401.svg`}
            alt="Error401"
          />
        </Grid>
        <Grid item mt="1.75rem">
          <Typography variant="h2Heavy">
            صفحه در دسترس شما نیست
            <Typography
              variant="h1Heavy"
              sx={{ color: theme.icon.errorPageExclamationSign.color }}
            >
              !
            </Typography>
          </Typography>
        </Grid>
        <Grid item mt="1rem">
          <Typography variant="h5Bold">
            شما دسترسی به این صفحه را ندارید.
          </Typography>
        </Grid>
        <Grid item mt="52px">
          <Button size="large" variant="contained" onClick={() => navigate(-1)}>
            بازگشت
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Error401;
